export default {
  'default': {
    'territorial': [
      'atlantic_forest_limit',
      'country',
      'political_level_1',
      'political_level_2',
      'watersheds',
    ],
    'land': [
      'indigenous_territories',
      'national_conservation_unit',
      'international_conservation_unit',
    ]
  },
  'simplified': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
      'special_territories',
      'biosphere_reserves',
      'geoparks',
    ]
  },
  'infrastructure': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'pnrh_level_1_basin',
      'pnrh_level_2_basin',
    ]
  },
  'fire': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
      'special_territories',
      'biosphere_reserve',
      'geoparks',
    ],
    'land': [
      'settlement',
      'quilombo',
      'indigenous_land_new',
      'federal_conservation_units_integral_protection',
      'federal_conservation_units_sustainable_use',
      'state_conservation_units_integral_protection',
      'state_conservation_units_sustainable_use',
    ]
  },
  'soil': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
    ]
  },
  'sentinel': {
    'territorial': [
      'country',
      'biome',
      'state',
      'city',
      'level_1_basin_pnhr',
      'level_2_basin_pnhr',
      'special_territories',
    ],
    'land': [
      'settlement',
      'quilombo',
      'indigenous_land_new',
      'federal_conservation_units_integral_protection',
      'federal_conservation_units_sustainable_use',
      'state_conservation_units_integral_protection',
      'state_conservation_units_sustainable_use',
    ]
  }
};
